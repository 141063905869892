var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ui-section" }, [
    _c(
      "div",
      { staticClass: "ui-section__title" },
      [
        _vm._t("title", function () {
          return [_vm._v("Название компонента")]
        }),
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "ui-section__body" },
      [
        _vm._t("default", function () {
          return [_vm._v("Компонент")]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }