<template>
  <div class="ui-section">
    <div class="ui-section__title">
      <slot name="title">Название компонента</slot>
    </div>

    <div class="ui-section__body">
      <slot>Компонент</slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UISection',
}
</script>

<style lang="sass">
.ui-section
  background: #f9f9f9
  border: 1px solid #DCDCDC
  border-radius: 8px
  font-size: 14px
  line-height: 16px
  letter-spacing: 0.5px
  color: #444444
  padding: 9px 12px
  & + &
    margin-top: 16px

  &__title
    font-weight: 500
    font-size: 20px
    margin-bottom: 20px
</style>
